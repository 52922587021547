import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Text,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

const ModalMethodBalanceWithdrawRequest: React.FC<ModalRootProps> = ({
  handleClose,
  ...restProps
}) => {
  const history = useHistory();

  const handleRequestWithdraw = useCallback(() => {
    history.push('/financial/transfers/bank/request');

    handleClose();
  }, [handleClose, history]);

  return (
    <Drawer size="md" {...restProps}>
      <DrawerOverlay />
      <DrawerContent borderBottomLeftRadius="base" borderTopLeftRadius="base">
        <DrawerHeader>Saldo por método de pagamento</DrawerHeader>

        <DrawerCloseButton />

        <DrawerBody>
          <Flex>
            <Text variant="smallcaps" color="gray.600" marginBottom="0.5">
              Saldo total disponível
            </Text>
          </Flex>
          {/* <Flex
            direction="column"
            // bg="gray.50"
            padding="4"
            borderRadius="base"
            // borderColor="gray.100"
            // borderWidth="1px"
            textAlign="center"
          >
            <Text variant="heading-3" marginBottom="1" color="green.500">
              <Currency value={157776.32} currency="BRL" />
            </Text>

            <Text variant="smallcaps" color="gray.600">
              Saldo total disponível
            </Text>
          </Flex>

          <VStack
            spacing="6"
            alignItems="center"
            justifyContent="flex-start"
            marginTop="6"
          >
            <Flex direction="column" width="100%">
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex direction="column">
                  <Text variant="smallcaps" color="gray.600" marginBottom="0.5">
                    Saldo total disponível
                  </Text>

                  <Text variant="subheading-1" color="orange.400">
                    (Pix, Cartão e Boleto)
                  </Text>
                </Flex>

                <Flex
                  height="2px"
                  bg="gray.100"
                  width="auto"
                  flex="1"
                  marginX="4"
                />

                <Flex direction="column" textAlign="right">
                  <Box>
                    <Text variant="heading-4">
                      <Currency value={157776.32} currency="BRL" />
                    </Text>

                    <Button
                      size="xs"
                      variant="link"
                      marginTop="2"
                      colorScheme="orange"
                      onClick={handleRequestWithdraw}
                    >
                      Solicitar saque
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            </Flex>

            <Flex direction="column" width="100%">
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex direction="column">
                  <Text variant="smallcaps" color="gray.600" marginBottom="0.5">
                    Saldo total disponível
                  </Text>

                  <Text variant="subheading-1" color="blue.400">
                    Paypal
                  </Text>
                </Flex>

                <Flex
                  height="2px"
                  bg="gray.100"
                  width="auto"
                  flex="1"
                  marginX="4"
                />

                <Flex direction="column" textAlign="right">
                  <Box>
                    <Text variant="heading-4">
                      <Currency value={157776.32} currency="BRL" />
                    </Text>

                    <Button
                      size="xs"
                      variant="link"
                      marginTop="2"
                      onClick={handleRequestWithdraw}
                    >
                      Solicitar saque
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            </Flex>

            <Flex direction="column" width="100%">
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex direction="column">
                  <Text variant="smallcaps" color="gray.600" marginBottom="0.5">
                    Saldo total disponível
                  </Text>

                  <Text variant="subheading-1" color="teal.400">
                    Parcelex
                  </Text>
                </Flex>

                <Flex
                  height="2px"
                  bg="gray.100"
                  width="auto"
                  flex="1"
                  marginX="4"
                />

                <Flex direction="column" textAlign="right">
                  <Box>
                    <Text variant="heading-4">
                      <Currency value={157776.32} currency="BRL" />
                    </Text>

                    <Button
                      size="xs"
                      variant="link"
                      marginTop="2"
                      colorScheme="teal"
                      onClick={handleRequestWithdraw}
                    >
                      Solicitar saque
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            </Flex>

            <Flex direction="column" width="100%">
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex direction="column">
                  <Text variant="smallcaps" color="gray.600" marginBottom="0.5">
                    Saldo total disponível
                  </Text>

                  <Text variant="subheading-1" color="yellow.700">
                    Criptomoeda
                  </Text>
                </Flex>

                <Flex
                  height="2px"
                  bg="gray.100"
                  width="auto"
                  flex="1"
                  marginX="4"
                />

                <Flex direction="column" textAlign="right">
                  <Box>
                    <Text variant="heading-4">
                      <Currency value={157776.32} currency="BRL" />
                    </Text>

                    <Button
                      size="xs"
                      variant="link"
                      marginTop="2"
                      color="yellow.800"
                      onClick={handleRequestWithdraw}
                    >
                      Solicitar saque
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </VStack> */}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ModalMethodBalanceWithdrawRequest;
